// import tokenReducer, { setToken } from './_token';
import nearByStoresReducer, {
  setNearByStores,
  getNearByStores,
} from './_nearByStores';
import addressReducer, { setAddress, getAddress } from './_address';
import mountDataReducer, {
  setToken,
  isReady,
  getToken,
  setCookie,
  getCookie,
  setStoreId,
  getStoreId,
  setAccessToken,
  getAccessToken,
  getHashAccount,
  setHashAccount,
} from './_mount';
import cartListReducer, { getCartList, setCartList } from './_cartList';
import refoundListReducer, {
  getRefoundList,
  setRefoundList,
} from './_refoundList';
import orderDetailReducer, {
  setOrderDetail,
  getOrderDetail,
} from './_orderDetail';
import viewCartReducer, { setViewCart, getViewCart } from './_gaViewCart';
import refundDetailReducer, {
  setRefundDetail,
  getRefundDetail,
} from './_refundDetail';
import storeTimeSlotsReducer, {
  setStoreTimeSlots,
  getStoreTimeSlots,
} from './_storeTimeSlots.js';
import userInfoReducer, { setUserInfo, getUserInfo } from './_userInfo.js';

import paymentReducer, { setPayment, getPayment } from './_payment.js';
import bonusPointReducer, { setBonusPoint, getBonusPoint } from './_bonusPoint';
import creditInfoReducer, {
  setCreditInfo,
  getCreditInfo,
  setPaymentMethods,
  getPaymentMethods,
  getNewCards,
  setNewCards,
} from './_creditInfo';

import localCacheReducer, { addLocalCache, getLocalCache } from './_localCache';

import keepaliveReducer, {
  addKeepalive,
  removeKeepalive,
  cleanKeepalive,
  getKeepalive,
} from './_keepalive';

import smartRecipeReducer from './_smartRecipe';

import cartAdditionItemsReducer, {
  setCartAdditionItems,
  getCartAdditionItems,
} from './_cartAdditionItems.js';

import additionItemsReducer, {
  setAdditionItems,
  getAdditionItems,
} from './_additionItems.js';

import successOrderCountReducer, {
  setSuccessOrderCount,
  getSuccessOrderCount,
} from './_successOrderCount';

import couponErrorMsgReducer, {
  setCouponErrorMsg,
  getCouponErrorMsg,
} from './_couponErrorMsg.js';

import modelCodeReducer, { setModelCode, getModelCode } from './_modelCode';
import recommendDataReducer, {
  setRecommendData,
  getRecommendData,
} from './_recommendData.js';

// 增加attribute token存取
import attributionTokenReducer, {
  setAttributionToken,
  getAttributionToken,
} from './_searchAttributionToken.js';

import currentUseCouponReducer, {
  setCurrentUseCoupon,
  getCurrentUseCoupon,
} from './_currentUseCoupon';

export const sliceActions = {
  setToken,
  setNearByStores,
  setCartList,
  setAddress,
  setRefoundList,
  setCookie,
  setOrderDetail,
  setRefundDetail,
  setStoreTimeSlots,
  setPayment,
  setBonusPoint,
  setCreditInfo,
  setPaymentMethods,
  setStoreId,
  setUserInfo,
  setAccessToken,
  setHashAccount,
  setNewCards,
  setViewCart,
  addLocalCache,
  addKeepalive,
  removeKeepalive,
  cleanKeepalive,
  setCartAdditionItems,
  setAdditionItems,
  setSuccessOrderCount,
  setCouponErrorMsg,
  setModelCode,
  setRecommendData,
  setAttributionToken,
  setCurrentUseCoupon,
};

export const sliceGetters = {
  getNearByStores,
  isReady,
  getCartList,
  getToken,
  getAddress,
  getRefoundList,
  getCookie,
  getOrderDetail,
  getStoreTimeSlots,
  getPayment,
  getBonusPoint,
  getRefundDetail,
  getCreditInfo,
  getPaymentMethods,
  getStoreId,
  getUserInfo,
  getAccessToken,
  getHashAccount,
  getNewCards,
  getViewCart,
  getLocalCache,
  getKeepalive,
  getCartAdditionItems,
  getAdditionItems,
  getSuccessOrderCount,
  getCouponErrorMsg,
  getAttributionToken,
  getModelCode,
  getRecommendData,
  getCurrentUseCoupon,
};

export default {
  mountData: mountDataReducer,
  nearByStores: nearByStoresReducer,
  cartList: cartListReducer,
  refoundList: refoundListReducer,
  address: addressReducer,
  orderDetail: orderDetailReducer,
  storeTimeSlots: storeTimeSlotsReducer,
  payment: paymentReducer,
  bonuxPoint: bonusPointReducer,
  refundDetail: refundDetailReducer,
  creditInfo: creditInfoReducer,
  userInfo: userInfoReducer,
  viewCart: viewCartReducer,
  localCache: localCacheReducer,
  keepalive: keepaliveReducer,
  smartRecipe: smartRecipeReducer,
  cartAdditionItems: cartAdditionItemsReducer,
  additionItems: additionItemsReducer,
  successOrderCount: successOrderCountReducer,
  couponErrorMsg: couponErrorMsgReducer,
  attributionToken: attributionTokenReducer,
  modelCode: modelCodeReducer,
  recommendData: recommendDataReducer,
  currentUseCoupon: currentUseCouponReducer,
};
